// inactivityCheck.js
import { loginFailureMsg, logout } from './UserAuthSlice';
import store from '../Store';
import { flush } from './userRoleSlice';

let inactivityTimeout;

const resetTimer = () => {
  // Clear the existing timer

  clearTimeout(inactivityTimeout);

  // Set a new timer for 1 hour (3600000 milliseconds)
  inactivityTimeout = setTimeout(() => {
    localStorage.removeItem('postLoginRedirect');
    localStorage.removeItem('avatarColor');
    store.dispatch(flush());
    store.dispatch(logout());
    store.dispatch(
      loginFailureMsg({
        message: 'You have been logged out due to exceeding the inactivity limit',
      }),
    );
  }, 3600000);
};

const activityHandler = () => {
  resetTimer();
};

// Attach event listeners to track user activity
const initializeInactivityCheck = () => {
  window.onload = resetTimer;
  window.addEventListener('mousemove', activityHandler);
  window.addEventListener('keypress', activityHandler);
  window.addEventListener('scroll', activityHandler);
};

// Export the function to initialize the inactivity check
export default initializeInactivityCheck;
