import { useSelector } from 'react-redux';

export const useAuthentication = () => {
  return useSelector((state) => state.UserAuthReducer.isAuthenticated);
};

export const useToken = () => {
  return useSelector((state) => state.UserAuthReducer.token);
};

export const GetUser = () => {
  return useSelector((state) => state.UserAuthReducer.user);
};

export const GetPermissions = () => {
  return useSelector((state) => state.UserRoleReducer.permissions);
};
