import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from 'src/hooks/useAuthentication';
import Loadable from 'src/components/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('src/components/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('src/components/BlankLayout')));

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import('src/views/dashboard/Dashboard')));

const SalesConfig = Loadable(lazy(() => import('src/views/masters/rules-config/salesConfig')));

const Assignments = Loadable(lazy(() => import('src/views/masters/assignment/assignments')));

const CitiesList = Loadable(lazy(() => import('src/views/masters/cities/CitiesList')));
const EmployeeBulkUpload = Loadable(lazy(() => import('../views/masters/employee/BulkUpload')));

const ConfigurationList = Loadable(
  lazy(() => import('src/views/masters/configuration/ConfigurationList')),
);

const ConfigDetails = Loadable(lazy(() => import('src/views/masters/rules-config/configDetails')));
const UserProfile = Loadable(lazy(() => import('../components/profile/UserProfile')));
const UserChangePassword = Loadable(lazy(() => import('../components/profile/UserChangePassword')));

// authentication
const Login = Loadable(lazy(() => import('src/views/authentication/auth1/Login')));
const ForgotPassword = Loadable(
  lazy(() => import('src/views/authentication/auth1/ForgotPassword')),
);

const TwoSteps = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps')));
const TwoSteps2 = Loadable(lazy(() => import('src/views/authentication/auth1/TwoSteps2')));
const Error = Loadable(lazy(() => import('src/views/authentication/Error')));

const MasterUserList = Loadable(lazy(() => import('src/views/masters/employee/List')));
const MasterUserAdd = Loadable(lazy(() => import('src/views/masters/employee/Add')));
const MasterUserEdit = Loadable(lazy(() => import('src/views/masters/employee/Add')));

const TravelRequestForm = Loadable(
  lazy(() => import('../views/travel-request-form/TravelRequestForm')),
);
const TravelClaimForm = Loadable(lazy(() => import('../views/travel-claim/TravelClaimForm')));
const TravelClaimList = Loadable(lazy(() => import('../views/travel-claim-list/TravelClaimList')));

const TravelRequestList = Loadable(
  lazy(() => import('../views/travel-request-list/TravelRequestList')),
);

const TravelRequestView = Loadable(
  lazy(() => import('../views/travel-request-list/TravelRequestCard')),
);

const ClaimReport = Loadable(lazy(() => import('src/views/masters/reports/ClaimReports')));
const ClaimReportByUser = Loadable(
  lazy(() => import('src/views/masters/reports/ClaimReportsByUser')),
);
const RequestReport = Loadable(lazy(() => import('src/views/masters/reports/TravelReports')));
const RequestReportByUser = Loadable(
  lazy(() => import('src/views/masters/reports/TravelReportsByUser')),
);

// auth
const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useAuthentication();
  return isAuthenticated ? element : <Navigate to="/auth/login" replace />;
};

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute element={<ModernDash />} />,
      },
      {
        path: '/dashboard',
        exact: true,
        element: <ProtectedRoute element={<ModernDash />} />,
      },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/user-change-password', element: <UserChangePassword /> },

      {
        path: '/rules-config',
        exact: true,
        element: <ProtectedRoute element={<SalesConfig />} />,
      },
      {
        path: '/travel-request-form',
        exact: true,
        element: <ProtectedRoute element={<TravelRequestForm />} />,
      },
      {
        path: '/travel-claim',
        exact: true,
        element: <ProtectedRoute element={<TravelClaimForm />} />,
      },
      {
        path: '/travel-claim/:id',
        exact: true,
        element: <ProtectedRoute element={<TravelClaimForm />} />,
      },
      {
        path: '/travel-claim/claim/:claim_id',
        exact: true,
        element: <ProtectedRoute element={<TravelClaimForm />} />,
      },
      {
        path: '/travel-claim-list',
        exact: true,
        element: <ProtectedRoute element={<TravelClaimList />} />,
      },
      {
        path: '/travel-request-view/:requestId',
        exact: true,
        element: <ProtectedRoute element={<TravelRequestForm />} />,
      },
      {
        path: '/travel-request-list',
        exact: true,
        element: <ProtectedRoute element={<TravelRequestList />} />,
      },
      {
        path: '/travel-requests-view/:requestId',
        exact: true,
        element: <ProtectedRoute element={<TravelRequestView />} />,
      },

      {
        path: '/masters/cities/list',
        exact: true,
        element: <ProtectedRoute element={<CitiesList />} />,
      },
      {
        path: '/masters/cities/edit/:id',
        exact: true,
        element: <ProtectedRoute element={<CitiesList />} />,
      },
      {
        path: '/masters/assignments',
        exact: true,
        element: <ProtectedRoute element={<Assignments />} />,
      },
      {
        path: '/configuration/list',
        exact: true,

        element: <ProtectedRoute element={<ConfigurationList />} />,
      },
      {
        path: '/masters/employee/bulk_upload',
        exact: true,
        element: <ProtectedRoute element={<EmployeeBulkUpload />} />,
      },
      {
        path: '/masters/employee/list',
        exact: true,
        element: <ProtectedRoute element={<MasterUserList />} />,
      },
      {
        path: '/masters/employee/add',
        exact: true,
        element: <ProtectedRoute element={<MasterUserAdd />} />,
      },
      {
        path: '/masters/configuration/list',
        exact: true,
        element: <ProtectedRoute element={<ConfigurationList />} />,
      },
      {
        path: '/masters/employee/edit/:userID',
        exact: true,
        element: <ProtectedRoute element={<MasterUserEdit />} />,
      },
      {
        path: '/config-details',
        exact: true,
        element: <ProtectedRoute element={<ConfigDetails />} />,
      },

      {
        path: 'reports/claim-report',
        exact: true,
        element: <ProtectedRoute element={<ClaimReport />} />,
      },
      {
        path: 'reports/claim-report/:user_id',
        exact: true,
        element: <ProtectedRoute element={<ClaimReportByUser />} />,
      },
      {
        path: '/travel-request-report',
        exact: true,
        element: <ProtectedRoute element={<RequestReport />} />,
      },
      {
        path: '/travel-request-report/:user_id',
        exact: true,
        element: <ProtectedRoute element={<RequestReportByUser />} />,
      },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/two-steps/:userId/:userEmail', element: <TwoSteps /> },
      { path: '/auth/two-steps2/:userId', element: <TwoSteps2 /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
