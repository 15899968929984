import axios from 'axios';
// import { useToken } from 'src/hooks/useAuthentication';

let tokenValue = null;

export const setToken = (token) => {

  tokenValue = token;
};

export const apiData = async (method, url, data) => {
  // const token =useToken();
 
  const options = await getOptionsConfig(method, url, data, tokenValue);

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then((res) => {
        if (res && res.data) {
          resolve({
            statusCode: res.status,
            msg: res.msg,
            data: res.data.data || res.data,
            pagination: res.data.total_count,
          });
        }
      })
      .catch((ex) => {
        console.error('Request Error:', ex);
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          resolve({
            statusCode: ex.response.status,
            error: ex.response.data,
            msg: ex.response.data?.msg,
          });
        } else {
          resolve({
            statusCode: 500,
            error: ex.message,
            msg: ex.response.data?.msg,
          });
        }
      });
  });
};

async function getOptionsConfig(method, url, data, token) {
  const isLoginUrl = url.includes('/auth');

  if (method === 'get') {
    return {
      method,
      url,
      params: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  } else if (method === 'post') {
    if (url.includes('signIn')) {
      return {
        method,
        url,
        data,
        headers: { 'Content-Type': 'application/json' },
      };
    } else {
      if (data instanceof FormData) {
        return {
          method,
          url,
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
        };
      } else {
        return {
          method,
          url,
          data,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
  } else {
    return {
      method,
      url,
      params: data,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  }
}

// -> previously working

// async function getOptionsConfig(method, url, data, token) {
//   const isAuthUrl = url.includes('/auth');

//   if (method === 'get') {
//     return {
//       method,
//       url,
//       params: data,
//       data,
//       headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
//     };
//   } else if (method === 'post') {
//     if (data instanceof FormData) {
//       return {
//         method,
//         url,
//         data,
//         headers: { 'Content-Type': 'multipart/form-data' },
//       };
//     } else {
//       return {
//         method,
//         url,
//         data,
//         headers: { 'Content-Type': 'application/json' },
//       };
//     }
//   }
//   return {
//     method,
//     url,
//     params: data,
//     data,
//     headers: { 'Content-Type': 'application/json' },
//   };
// }

// -> OLD

// async function getOptionsConfig(method, url, data) {
//   if (method === 'get' || method === 'put') {
//     return {
//       method,
//       url,
//       params: data,
//       headers: { 'Content-Type': 'application/json' },
//     };
//   }
//   return {
//     method,
//     url,
//     params: data,
//     data,
//     headers: { 'Content-Type': 'application/json' },
//   };
// }

// async function getOptionsConfig(method, url, data) {
//   if (method === 'get') {
//     return {
//       method,
//       url,
//       params: data,
//       headers: { 'Content-Type': 'application/json' },
//     };
//   } else if (method === 'put' || method === 'post') {
//     let requestData;
//     let contentType;
//     if (data instanceof FormData) {
//       requestData = data;
//       contentType = 'multipart/form-data';
//     } else {
//       requestData = JSON.stringify(data);
//       contentType = 'application/json';
//     }
//     return {
//       method,
//       url,
//       params: data,
//       data: requestData,
//       headers: { 'Content-Type': contentType },
//     };
//   }
//   return {
//     method,
//     url,
//     params: data,
//     headers: { 'Content-Type': 'application/json' },
//   };
// }
